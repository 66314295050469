import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { device } from '../device'
import { palette, spacers } from '../styles/theme'
import { useOutsideInputAction } from '../utils/useOutsideInputAction'
import { layers } from '../styles/layers'
import { LogoIcon } from './icons/logo-icon'
import { MagnifyingGlass } from './icons/magnifying-glass'

const logoDimensions = {
  height: 36,
  width: 33.99,
}

const HeaderElement = styled.header`
  margin: 0;
  padding: 0;
  padding-left: ${spacers.spacer3};
  padding-right: ${spacers.spacer3};
  height: ${spacers.spacer7};
  position: sticky;
  z-index: ${layers.header};
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${palette.color6};
  @media ${device.intermediate} {
    padding-left: ${spacers.spacer6};
    padding-right: ${spacers.spacer6};
    height: ${spacers.spacer8};
  }
`

const Nav = styled.nav`
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: ${spacers.spacer3};
  width: 100%;
  display: ${props => (props.open ? 'none' : 'flex')};
  justify-content: flex-start;
  align-items: center;
  @media ${device.intermediate} {
    display: flex;
  }
`

const NavItems = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const NavItemContainer = styled.li`
  margin: 0;
  margin-right: ${props => (props.last ? 0 : spacers.spacer3)};
  padding: 0;
`

const NavItemLink = styled.a`
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 2.25rem;
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  color: ${palette.color1};
  transition: 0.3s color ease-in-out;
  :hover {
    color: ${palette.color3};
  }
  @media ${device.intermediate} {
    font-size: 3rem;
  }
`

const NavItem = ({ href, children, last }) => (
  <NavItemContainer last={last}>
    <NavItemLink href={href}>{children}</NavItemLink>
  </NavItemContainer>
)

NavItem.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  last: PropTypes.bool,
}

const LogoSection = styled.section`
  margin: 0;
  margin-right: ${spacers.spacer3};
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${device.intermediate} {
    margin-right: ${spacers.spacer6};
  }
`

const LogoLink = styled.a.attrs({ href: '/' })`
  margin: 0;
  padding: 0;
  height: ${logoDimensions.height}px;
  width: ${logoDimensions.width}px;
  display: block;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  @media ${device.intermediate} {
    width: 56px;
    height: 56px;
  }
`

const LeftBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const SearchBox = styled.div`
  position: fixed;
  top: 10px;
  right: ${spacers.spacer3};
`

const SearchText = styled.input.attrs({
  id: 'idSearch',
  type: 'text',
  name: '',
  autocomplete: 'off',
  placeholder: 'Search...',
})`
  margin: 0;
  padding: 0;
  padding-left: ${props => (props.open ? spacers.spacer1 : 0)};
  padding-right: ${props => (props.open ? '30px' : 0)};
  width: ${props => (props.open ? '235px' : '36px')};
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.5rem;
  line-height: 1.5;
  background: ${palette.color1};
  color: ${palette.color6};
  border: none;
  outline: none;
  transition: 0.4s;
  border-radius: 25px;
  @media ${device.intermediate} {
    width: 247px;
    padding-left: ${spacers.spacer1};
    padding-right: 30px;
  }
  @media ${device.web} {
    width: 412px;
  }
`

const SearchButtom = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${palette.color1};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  box-shadow: none;
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
  }
`

export const Header = ({ isBlog }) => {
  const [openSearch, setOpenSearch] = useState(false)

  const inputSearchRef = useRef()
  const buttomSearchRef = useRef()

  useOutsideInputAction(inputSearchRef, buttomSearchRef, () => {
    setOpenSearch(false)
    inputSearchRef.current.value = ''
  })

  const search = () => {
    if (!openSearch) {
      setOpenSearch(true)
      inputSearchRef.current.focus()
    } else {
      console.log('Searching...')
      inputSearchRef.current.value = ''
      setOpenSearch(false)
    }
  }

  return (
    <HeaderElement>
      <LeftBox>
        <LogoSection>
          <LogoLink>
            <LogoIcon
              height={logoDimensions.height}
              width={logoDimensions.width}
            />
          </LogoLink>
        </LogoSection>
        {isBlog ? (
          <Nav open={openSearch}>
            <NavItems>
              <NavItem href="/blog" last={true}>
                Blog
              </NavItem>
            </NavItems>
          </Nav>
        ) : null}
      </LeftBox>
      {!true && (
        <SearchBox>
          <SearchText open={openSearch} ref={inputSearchRef} />
          <SearchButtom onClick={search} ref={buttomSearchRef}>
            <MagnifyingGlass />
          </SearchButtom>
        </SearchBox>
      )}
    </HeaderElement>
  )
}

Header.propTypes = {
  isBlog: PropTypes.bool.isRequired,
}
