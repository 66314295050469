import React from 'react'
import PropTypes from 'prop-types'

import { palette } from '../../styles/theme'
import { Svg } from './svg'

export const InstagramIcon = ({ fill = palette.color1, size }) => {
  const viewBox = `0 0 ${size} ${size}`
  return (
    <Svg
      viewBox={viewBox}
      id="icon-play"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <g>
        <path
          fill={fill}
          d="M18.25,0H7.75C3.47,0,0,3.47,0,7.75v10.51C0,22.53,3.47,26,7.75,26h10.51c4.27,0,7.75-3.47,7.75-7.75V7.75 C26,3.47,22.53,0,18.25,0z M23.38,18.25c0,2.83-2.3,5.13-5.13,5.13H7.75c-2.83,0-5.13-2.3-5.13-5.13V7.75 c0-2.83,2.3-5.13,5.13-5.13h10.51c2.83,0,5.13,2.3,5.13,5.13L23.38,18.25L23.38,18.25z"
        />
        <path
          fill={fill}
          d="M13,6.28c-3.71,0-6.72,3.02-6.72,6.72s3.02,6.72,6.72,6.72s6.72-3.02,6.72-6.72S16.71,6.28,13,6.28z M13,17.11 c-2.27,0-4.11-1.84-4.11-4.11S10.73,8.89,13,8.89c2.27,0,4.11,1.84,4.11,4.11C17.11,15.27,15.27,17.11,13,17.11z"
        />
        <circle fill={fill} cx="19.74" cy="6.33" r="1.61" />
      </g>
    </Svg>
  )
}

InstagramIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number.isRequired,
}
