import React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import { device } from '../device'
import { palette, spacers } from '../styles/theme'

const theme = {}

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  main {
    margin: 0 !important;
    flex: 1;
  }

  main > div {
    width: 100% !important;
    max-width: 1204px !important;
  }

  #innerHtml h2, h3, h4, h5, h6 {
    margin: 0;
    margin-bottom: ${spacers.spacer5};
    padding: 0;
    font-family: 'Jorge-Luis-Cafe-Bold';
    font-size: 1.5rem;
    line-height: 1.5;
    color: ${palette.color6};
  }

  #innerHtml p {
    margin: 0;
    margin-bottom: ${spacers.spacer5};
    padding: 0;
    font-family: 'Jorge-Luis-Cafe-Cafe';
    font-size: 1.25rem;
    line-height: 1.5;
    text-align: left;
    color: ${palette.color6};
  }

  #innerHtml a {
    color: ${palette.color6};
    text-decoration-color: ${palette.color4};
    box-shadow: none;
    cursor: pointer;
  }

  #innerHtml ul, ol {
    margin: 0;
    margin-left: ${spacers.spacer3};
    margin-bottom: ${spacers.spacer5};
    padding: 0;
  }

  #innerHtml li {
    margin: 0;
    padding: 0;
    padding-left: ${spacers.spacer1};
    font-family: 'Jorge-Luis-Cafe-Cafe';
    font-size: 1.25rem;
    line-height: 1.5;
    color: ${palette.color6};
    text-align: left;
  }

  #innerHtml li ul {
    margin: 0;
    margin-left: ${spacers.spacer2};
    padding: 0;
  }

  #innerHtml li p {
    margin : 0;
  }

  #innerHtml blockquote {
    margin: 0;
    margin-bottom: ${spacers.spacer5};
    padding: 0 ${spacers.spacer3};
  }

  #innerHtml blockquote p {
    margin: 0;
    padding: 0;
    font-family: 'Jorge-Luis-Cafe-Italic';
    font-size: 1.5rem;
    color: ${palette.color4};
  }

  /* RESPONSIVE INTERMEDIATE */
  @media ${device.intermediate} {
    #innerHtml h2, h3, h4, h5, h6 {
      font-size: 1.875rem;
    }

    #innerHtml p {
      font-size: 1.5rem;
      line-height: 2;
    }

    #innerHtml li {
      font-size: 1.5rem;
      line-height: 2;
    }

    #innerHtml blockquote {
      padding: 0 ${spacers.spacer6};
    }

    #innerHtml blockquote p {
      line-height: 2;
    }
  }

  /* RESPONSIVE WEB */
  @media ${device.web} {
    #innerHtml blockquote {
      padding: 0 ${spacers.spacer7};
    }
  } 
`

export const WrapRootElement = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

WrapRootElement.propTypes = {
  children: PropTypes.node.isRequired,
}
