import React from 'react'
import PropTypes from 'prop-types'

import { palette } from '../../styles/theme'
import { Svg } from './svg'

export const TwitterIcon = ({ fill = palette.color1, size }) => {
  const viewBox = `0 0 ${size} ${size}`
  return (
    <Svg
      viewBox={viewBox}
      id="icon-play"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        fill={fill}
        d="M25.6,4.7C25,5,24.4,5.2,23.8,5.3C24.5,4.7,25,3.9,25.3,3l0,0c0.1-0.3-0.2-0.5-0.4-0.3l0,0
        c-0.9,0.5-1.8,0.9-2.7,1.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-1-0.9-2.3-1.4-3.7-1.4c-0.6,0-1.2,0.1-1.8,0.3
        c-1.8,0.6-3.2,2.1-3.7,3.9c-0.2,0.7-0.2,1.4-0.1,2.1c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0C8,8.3,4.4,6.4,1.8,3.3
        l0,0c-0.1-0.2-0.4-0.1-0.5,0l0,0C0.9,4.2,0.6,5.1,0.6,6.1c0,1.5,0.6,3,1.7,4C1.8,10,1.4,9.8,1,9.6l0,0c-0.2-0.1-0.4,0-0.4,0.3l0,0
        c0,2.3,1.3,4.3,3.3,5.2c0,0-0.1,0-0.1,0c-0.3,0-0.6,0-0.9-0.1l0,0c-0.2,0-0.4,0.2-0.3,0.4l0,0c0.6,2,2.4,3.5,4.4,3.8
        c-1.7,1.2-3.7,1.8-5.8,1.8H0.4c-0.2,0-0.4,0.1-0.4,0.3c-0.1,0.2,0,0.4,0.2,0.5c2.3,1.4,5,2.1,7.7,2.1c2.4,0,4.6-0.5,6.6-1.4
        c1.8-0.8,3.5-2.1,4.8-3.6c1.3-1.4,2.3-3.1,3-5c0.7-1.8,1-3.6,1-5.4V8.3c0-0.3,0.1-0.6,0.4-0.7c0.9-0.7,1.6-1.5,2.2-2.4l0,0
        C26.1,4.9,25.9,4.6,25.6,4.7L25.6,4.7z"
      />
    </Svg>
  )
}

TwitterIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number.isRequired,
}
