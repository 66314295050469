import { device } from '../device'

export const palette = {
  color1: '#fafafa',
  color2: '#e4e7ec',
  color3: '#a0a0a0',
  color4: '#5e5e5e',
  color5: '#2f2f2f',
  color6: '#121212',
}

export const maxContentWidth = 1200

export const spacers = {
  spacer1: '11px',
  spacer2: '22px',
  spacer3: '24px',
  spacer4: '30px',
  spacer5: '36px',
  spacer6: '48px',
  spacer7: '72px',
  spacer8: '96px',
  spacer9: '144px',
}

const baseFontSize = 16

export const absoluteFontSizes = {
  mobile: {
    title: 36,
    description: 20,
  },
  intermediate: {
    title: 36,
    description: 24,
  },
  web: {
    title: 36,
    description: 24,
  },
}

export const relativeFontSizes = {
  mobile: {
    title: absoluteFontSizes.mobile.title / baseFontSize,
    description: absoluteFontSizes.mobile.description / baseFontSize,
  },
  intermediate: {
    title: absoluteFontSizes.intermediate.title / baseFontSize,
    description: absoluteFontSizes.intermediate.description / baseFontSize,
  },
  web: {
    title: absoluteFontSizes.web.title / baseFontSize,
    description: absoluteFontSizes.web.description / baseFontSize,
  },
}

export const absoluteTitleStyles = `
  font-family: 'Jorge-Luis-Cafe-Bold';
  line-height: 1;
  font-size: ${absoluteFontSizes.mobile.title}px;
  @media ${device.intermediate} {
    font-size: ${absoluteFontSizes.intermediate.title}px;
  }
  @media ${device.web} {
    font-size: ${absoluteFontSizes.web.title}px;
  }
`

export const titleStyles = `
  font-family: 'Jorge-Luis-Cafe-Bold';
  line-height: 1;
  font-size: ${relativeFontSizes.mobile.title}rem;
  @media ${device.intermediate} {
    font-size: ${relativeFontSizes.intermediate.title}rem;
  }
  @media ${device.web} {
    font-size: ${relativeFontSizes.web.title}rem;
  }
`

export const descriptionStyles = `
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: ${relativeFontSizes.mobile.description}rem;
  line-height: 1.2;
  @media ${device.intermediate} {
    font-size: ${relativeFontSizes.intermediate.description}rem;
    line-height: 1.25;
  }
  @media ${device.web} {
    font-size: ${relativeFontSizes.web.description}rem;
    line-height: 1.5;
  }
`
