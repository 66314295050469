const deviceSize = {
  mobile: 0,
  intermediate: 845,
  web: 1090,
}

export const device = {
  mobile: `(min-width: ${deviceSize.mobile}px)`,
  intermediate: `(min-width: ${deviceSize.intermediate}px)`,
  web: `(min-width: ${deviceSize.web}px)`,
}
