import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { device } from '../../device'
import { palette, spacers } from '../../styles/theme'
import { FacebookIcon } from '../icons/facebook'
import { TwitterIcon } from '../icons/twitter'
import { InstagramIcon } from '../icons/instagram'

const socialIconDimensions = { size: 26 }

const FooterContainer = styled.footer.attrs({
  id: 'footer',
})`
  margin: 0;
  padding-top: ${spacers.spacer1};
  padding-left: ${spacers.spacer2};
  padding-bottom: ${spacers.spacer1};
  padding-right: ${spacers.spacer2};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${palette.color6};
  @media ${device.intermediate} {
    padding: 0 ${spacers.spacer3};
    height: 56px;
    flex-direction: row;
    justify-content: space-between;
  }
`

// Este espaciador hace un `position: sticky;` artesanal cuando el player esta abierto
const PlayerSpacer = styled.div`
  height: 56px;
`

const RightInfoWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media ${device.intermediate} {
    justify-content: flex-start;
  }
`

const RightInfo = styled.span`
  margin: 0;
  margin-right: ${props => (props.left ? '5px' : 0)};
  margin-bottom: 11px;
  padding: 0;
  text-align: center;
  color: ${palette.color1};
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.25rem;
  line-height: 1;
  @media ${device.intermediate} {
    margin-bottom: 0px;
  }
`

const SocialIcons = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SocialIconItem = styled.li`
  margin: 0;
  margin-right: ${props => (props.last ? 0 : spacers.spacer2)};
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  @media ${device.intermediate} {
    margin-right: ${props => (props.last ? 0 : spacers.spacer3)};
  }
`

const SocialLink = styled.a.attrs({
  target: '_blank',
})`
  margin: 0;
  padding: 0;
  width: ${socialIconDimensions.size}px;
  height: ${socialIconDimensions.size}px;
  text-decoration: none;
  box-shadow: none;
`

const showSocial = false

export const Footer = ({ playerOpen }) => (
  <>
    <FooterContainer>
      <RightInfoWrapper>
        <RightInfo left={true}>&copy; 2020 thm.com </RightInfo>
        <RightInfo>All Rights Reserved</RightInfo>
      </RightInfoWrapper>
      {showSocial ? (
        <SocialIcons>
          <SocialIconItem>
            <SocialLink href={'https://facebook.com'}>
              <FacebookIcon size={socialIconDimensions.size} />
            </SocialLink>
          </SocialIconItem>
          <SocialIconItem>
            <SocialLink href={'https://twitter.com'}>
              <TwitterIcon size={socialIconDimensions.size} />
            </SocialLink>
          </SocialIconItem>
          <SocialIconItem last={true}>
            <SocialLink href={'https://instagram.com'}>
              <InstagramIcon size={socialIconDimensions.size} />
            </SocialLink>
          </SocialIconItem>
        </SocialIcons>
      ) : null}
    </FooterContainer>
    {playerOpen ? <PlayerSpacer /> : null}
  </>
)

Footer.propTypes = {
  playerOpen: PropTypes.bool.isRequired,
}
