import React from 'react'
import PropTypes from 'prop-types'

import { palette } from '../../styles/theme'
import { Svg } from './svg'

export const LogoIcon = ({ fill = palette.color1, width, height }) => {
  const viewBox = `0 0 ${width} ${height}`
  return (
    <Svg viewBox={viewBox} id="icon-play" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M29.82,15.84c-1.47-1.22-3.25-2.65-4.65-3.51c-0.38-0.47-0.81-0.9-1.26-1.3c0.04-0.13,0.09-0.25,0.15-0.36
  c0.17,0.01,0.34,0.02,0.52,0.02c0.25,0,1.7,0.46,1.7,0.46s-0.24,1.26,1.04,1.83c1.45,0.65,3.4-0.93,4-2.12
  c0.7-1.38,0.72-2.34,0.13-3.15c-0.11-0.23-0.28-0.4-0.5-0.53c-0.08-0.07-0.15-0.14-0.24-0.21c-0.35-0.07-0.73-0.11-1.13-0.09
  c-1.57,0.08-2.52,1.2-2.58,1.23c0.05-0.05,0.76-1.46,2.56-1.59c0.18,0,0.35,0.01,0.52,0.02c-1.77-1.13-0.79-4.99-5.49-5.15
  c-1.37-0.05-3.11,1.18-3.11,1.18S20.35,0,16.77,0C13.14,0,12,2.75,12,2.75s-2.06-1.4-3.43-1.36c-4.7,0.16-3.73,4.02-5.5,5.15
  c0.16-0.02,0.34-0.03,0.52-0.02c1.8,0.14,2.51,1.55,2.56,1.59C6.09,8.09,5.14,6.97,3.57,6.89C3.16,6.87,2.78,6.91,2.43,6.98
  c-1.29,0.99-1.52,2.08-0.61,3.89c0.6,1.19,2.56,2.78,4,2.12c1.28-0.58,1.04-1.83,1.04-1.83s1.45-0.46,1.7-0.46
  c0.08,0,0.16,0,0.24-0.01c0.15,0.16,0.33,0.38,0.47,0.66C9,11.61,8.74,11.89,8.49,12.18c-1.43,0.83-3.35,2.37-4.91,3.67
  c-2.6,2.15-4.13,4.61-3.4,5.48c0.65,0.79,2.9,0.03,5.24-1.7c0.13-1.45,0.62-2.19,0.62-2.19c-0.12,0.53-0.14,1.27-0.13,1.82
  c0,0,0.01-0.01,0.01-0.01c0.02,4.81,3.17,8.87,7.52,10.27c0.24,0.06,1.61,0.41,2.49,0.39c0,0-0.93,0.38-2.55,0.14l-1.07,4.46
  c0,0.82,0.76,1.49,1.68,1.49h5.57c0.92,0,1.68-0.67,1.68-1.49l-1.07-4.46c-1.51,0.19-2.37-0.16-2.37-0.16
  c0.72,0.02,1.77-0.21,2.26-0.33c0-0.01,0-0.01,0-0.02c4.34-1.38,7.5-5.41,7.57-10.19c0,0,0,0,0,0c0.01-0.55,0.01-1.42-0.13-2.02
  c0,0,0.54,0.82,0.63,2.4c2.29,1.65,4.46,2.37,5.1,1.59C33.94,20.45,32.42,18,29.82,15.84z"
      />
    </Svg>
  )
}

LogoIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
