import React from 'react'
import PropTypes from 'prop-types'

import { palette } from '../../styles/theme'
import { Svg } from './svg'

export const FacebookIcon = ({ fill = palette.color1, size }) => {
  const viewBox = `0 0 ${size} ${size}`
  return (
    <Svg
      viewBox={viewBox}
      id="icon-play"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        fill={fill}
        d="M22.5,0H3.5C1.57,0,0,1.57,0,3.5V22.5C0,24.43,1.57,26,3.5,26h9.37l0.02-9.29h-2.41
          c-0.31,0-0.57-0.25-0.57-0.57l-0.01-2.99c0-0.32,0.25-0.57,0.57-0.57h2.41V9.68c0-3.36,2.05-5.19,5.05-5.19h2.46
          c0.31,0,0.57,0.25,0.57,0.57v2.53c0,0.31-0.25,0.57-0.57,0.57l-1.51,0c-1.63,0-1.94,0.77-1.94,1.91v2.51h3.58
          c0.34,0,0.61,0.3,0.57,0.64l-0.35,2.99c-0.03,0.29-0.28,0.5-0.57,0.5h-3.21L16.92,26h5.57c1.93,0,3.5-1.57,3.5-3.5V3.5
          C26,1.57,24.43,0,22.5,0z"
      />
    </Svg>
  )
}

FacebookIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number.isRequired,
}
