import { useEffect } from 'react'

export const useOutsideInputAction = (refInput, refButtom, callback) => {
  const handleAction = e => {
    if (
      refInput.current &&
      !refInput.current.contains(e.target) &&
      !refButtom.current.contains(e.target)
    ) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleAction)
    return () => {
      document.removeEventListener('click', handleAction)
    }
  })
}
