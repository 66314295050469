import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { device } from '../device'
import { spacers } from '../styles/theme'
import { Header } from '../components/header'
import { Footer } from '../components/home/footer'

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const MainContainer = styled.main`
  margin-top: ${spacers.spacer5};
  margin-bottom: auto;
  padding: 0 ${spacers.spacer3};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    margin-top: ${spacers.spacer7};
    padding: 0 ${spacers.spacer6};
  }
`

const MainContent = styled.div`
  margin: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    width: 748px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media (min-width: 1200px) {
    width: 1146px;
    > :nth-child(3n) {
      margin-right: 0;
    }
  }
`

const Layout = ({ isBlog, location, title, children }) => (
  <Container>
    <Header isBlog={isBlog} />
    <MainContainer>
      <MainContent>{children}</MainContent>
    </MainContainer>
    <Footer />
  </Container>
)

Layout.propTypes = {
  isBlog: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
}

export default Layout
